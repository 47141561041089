<template>
  <div class="bg-auth row justify-center text-center items-center">
    <div>
      <h2 class="row items-center">
        {{ $t('Unauthorized') }}

        <q-icon name="error" class="q-ml-md" color="negative" />
      </h2>

      <div>
        <q-btn
          to="/"
          :label="$t('Home')"
          color="light-blue-9"
          class="q-mr-sm"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components

export default {
  name: 'Unauthorized',
  components: {
  },
  data () {
    return {
      user: JSON.parse(localStorage.getItem('userData'))
    }
  }
}
</script>

<style>
.bg-auth {
  min-height: 100vh;
  background: radial-gradient(at 50% -20%, #908392, #0d060e) fixed;
}
</style>
